@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.container {
    height: 100%;
    width: 100%;
    margin: 0px;
}

.container-info {
    display: inline-block;
    position: relative;
    width: 100%;
    min-height: 100%;
    max-height: 100%;
    flex-grow: 1;
}

.logoStyle {
    left: 4.86%;
    top: 1.37%;
    position: relative;
}

@media (max-width:768px){
    .logoStyle{
        width: 20%;
    }
}

.checkboxStyle {
    padding-left: 4%;
}

.forgotpassword_link {
    color: #7852FB;
    font-weight: 500;
    font-size: 16px;
}

.linktext_redirect {
    text-decoration: none;
    color: #7852FB;
    
}

.container-main {
    display: inline-block;
    position: relative;
    width: 100%;
    flex-grow: 1;
    min-height: 100%;
    max-height: 100%;
}

@media (min-width: 768px) {
    .container-info {
        float: left;
        left: 0;
        top: 0;
        height: 100%;
        width: 50%;
        flex-grow: 1;
        min-height: 100%;
    }
}

@media only screen and (max-height: 768px) {
   .block {
    margin: 0px !important;
   }
}

@media only screen and (max-height: 670px) {
    .login-container {
        overflow-y: scroll;
    }
    .login-container::-webkit-scrollbar {
        display: none;
    }
    .login-container {
        scrollbar-width: none;
    }
}


@media (min-width: 768px) {
    .container-main {
        float: right;
        right: 0;
        top: 0;
        height: 100%;
        width: 50%;
        flex-grow: 1;
        min-height: 100%;

    }
}

@media only screen and (max-width:768px){
    .container-main {
    display: none;
    }
}

@media only screen and (max-width: 900px) {
    .container-main {
       display: none;

    }
}

@media only screen and (max-width: 896px) {
    .container-main {
       display: none;

    }
}

@media only screen and (max-width: 600px) {
    .container-main {
       display: none;

    }
}