.main_container {
    display: flex;
    overflow-y: hidden;
    align-items: center;
    justify-content: center;
    /* text-align: center; */
    height: '100%'
    
}

.logo {
    position: absolute;
    margin: 14px;
}

.resetpassword_container {
    position: absolute;
    width: 451px;
    height: 392px;
}

.resetlink_text {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    width: 432px;
    text-align: left;
    color: #212121;
}

.textfield {
    background: #FFFFFF;
    border-radius: 8px;
    width: 450px;
    height: 65px;
}

.cancelbtn {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    text-decoration: none;
    text-align: center;
    color: #212121;
}

.item-box {
    padding: 1rem;
    margin: 1rem;
    width: 450px;
    text-align: center;
  }

@media(max-width:599px){
    .resetpassword_container {
        width: 100%;
        height: 60%;
    }
    .textfield {
        width: 100%;
    }
    .resetlink_text {
        width: 100%;
    }
    .item-box {
        width: 100%;
        margin: 0;
        padding: 11px;
      }
}

@media (min-width: 599px) and (max-width: 959px) {
    .resetpassword_container {
        width: 450px;
    }
    .textfield {
        width: 450px;
    }
    .resetlink_text {
        width: 450px;
    }
    .item-box {
        width: 450px;
        margin: 0;
        padding: 11px;
      }
}

@media (min-width: 959px) {
    .resetpassword_container {
        width: 450px;
    }
    .textfield {
        width: 450px;
    }
    .resetlink_text {
        width: 450px;
    }
    .item-box {
        width: 450px;
        margin: 0;
        padding: 11px;
      }
}