

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");

@font-face {
  font-family: "Helvetica Neue";
  src: url("./assets/fonts/HelveticaNeue-Medium.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("./assets/fonts/HelveticaNeue-Bold.otf") format("opentype");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("./assets/fonts/HelveticaNeue-RegularItalic.otf") format("opentype");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("./assets/fonts/HelveticaNeue-Regular.otf") format("opentype");
  font-style: normal;
  font-weight: 300;
}

:root {
  font-synthesis: none;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 1rem;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  /* Modify */
  /* background-color: #E5E5E5; */
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}

::-ms-reveal {
  display: none;
}

mark {
  background: #dfd6fd;
  color: inherit;
  padding-inline: 4px;
  border-radius: 4px;
}

.recharts-default-legend {
  visibility: hidden;
}
.MuiAutocomplete-noOptions {
  display: none;
}