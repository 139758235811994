/* Basic search page styles */
.search-container {
  /* padding: 0 66px; */
  background-color: #FAF8F5;
  min-height: calc(100vh - 140px);
}

.search-builder-container {
  /* padding-left: 66px; */
  background-color: #FAF8F5;
  padding-bottom: 10px;
}

.search-header  {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.banner-image {
  padding: 0 56px 0 0;
  position: absolute;
  right: 0;
  top: 50px;
}

.header-text {
  font-family: Lora;
}

/* Personal Library Screen */
.lib-container {
  padding: 0 66px;
  min-height: 75vh;
}


.tabs-container  {
  border: 1px solid #D1D5DB;
  margin-block: 1rem;
}

/* Profile screen */
.profile-screen {
  background-color: #f5f5f5;
  padding: 0 66px;
  min-height: 80vh;
  margin-bottom: 3rem;
}

.mr-container {
  margin: 0 66px;
  min-height: 80vh;
}

.focus-visible  > input:focus-visible {
  outline: 1px solid #7852FB !important;
  border: 1px solid #7852FB !important;
}

.basicsearch-image {
  width: 100%;
  /* height: 115%; */
  height: auto;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
}

.product-package-details {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
  color: #7852FB;
  text-transform: capitalize;
  font-size: 20px;
}

.gradient-overlay {
  position: absolute;
  width: 100%;
  height: 7vh;
  bottom: 0;
  right: 0;
  background: linear-gradient(
    180deg,
    rgba(250, 248, 245, 0.0),
    rgba(250, 248, 245, 0.2),
    rgba(250, 248, 245, 0.5),
    rgba(250, 248, 245, 0.8),
    rgba(250, 248, 245, 1)
  );
}

.image-parent-container {
  width: 100%;
  height: 40vh;
  position: relative;
  overflow: hidden;
}

.flex-grow-container {
  padding: 0 66px;
  flex-grow: 2;
}

.basic-button{
  /* height: 56px; */
  height: 53px;
  width: 130px;
  margin-bottom: 0; 
}
.dropdown-container {
  padding: 12px 24px 24px 12px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.nav-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 0;
}


/* Media query */
@media only screen and (max-width: 600px) {
  .search-container {
    /* padding: 0 12px; */
     height: 100%; 
    /* width: "100%"; */
  }

  /* .basicsearch-image {
    object-fit: contain;
    height: auto;
  } */

  .product-package-details {
    top: 5px;
    left: 5px;
    z-index: 1;
    color: #7852FB;
    text-transform: capitalize;
    font-size: 12px;
    
  }

  .image-parent-container {
    width: 100%;
    height: 16vh;
    position: relative;
    overflow: hidden;
  }

  /* .gradient-overlay {
    position: absolute;
    width: 100%;
    height: 3vh;
    bottom: 0;
    right: 0;
    
  } */

  .flex-grow-container {
    padding: 0 20px;
    flex-grow: 2;
  }

  .basic-button{
    width: 100%;
    margin-bottom: 32; 
  }

  .dropdown-container {
    grid-template-columns: 1fr; /* Stack items vertically on mobile */
    /* width: 100%; */
  }

  .search-builder-container {
    padding: 0 12px;
  }

  .lib-container {
    padding: 0 12px;
  }

  .profile-screen {
    padding: 0 36px;
  }

  .mr-container{
    margin: 0 15px;
  }

  .tabs-container  {
    border: none;
    margin-block: none;
  }
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
  .search-container {
    /* padding: 0 12px; */
     height: 100%; 
    /* width: "100%"; */
  }

  .basicsearch-image {
    object-fit: contain;
    height: auto;
  }

  .product-package-details {
    top: 5px;
    left: 5px;
    z-index: 1;
    color: #7852FB;
    text-transform: capitalize;
    font-size: 16px;
    
  }

  .image-parent-container {
    width: 100%;
    height: 20vh;
    position: relative;
    overflow: hidden;
  }

  .gradient-overlay {
    position: absolute;
    width: 100%;
    height: 10vh;
    bottom: 0;
    right: 0;
    
  }

  .flex-grow-container {
    padding: 0 20px;
    flex-grow: 2;
  }

  .basic-button{
    width: 100%;
    margin-bottom: 32; 
  }

  .dropdown-container {
    grid-template-columns: 1fr; /* Stack items vertically on mobile */
    /* width: 100%; */
  }

  .search-builder-container {
    padding: 0 12px;
  }

  .lib-container {
    padding: 0 12px;
  }

  .profile-screen {
    padding: 0 36px;
  }
}

@media only screen and (max-height: 765px) and (min-width: 600px) {
  .search-container {
    height: 100%;
    padding-bottom: 100px;
  }
}

@media only screen and (min-height: 900px) {
  .search-field {
    margin-block: 5rem;
    margin-inline: 2rem;
  }
}

@media only screen and (min-height: 1000px) {
  .search-field {
    margin-block: 6rem;
    margin-inline: 2rem;
  }
}

@media only screen and (min-width: 1227px) and (max-width: 1500px) {
  .banner-image {
    width: 356px;
    top: 86px
  }
}

@media only screen and (max-width: 1226px) {
  .banner-image {
    width: 250px;
    top: 100px;
  }
}

@media only screen and (max-width: 1200px) {
  .banner-image {
    display: none;
  }
}

@media (max-width: 1024px) {
  .search-container {
    width: 100%;
    overflow: hidden;
  }
}