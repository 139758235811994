@import url("https://fonts.googleapis.com/css2?family=Lora:wght@600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

body {
    margin: 0;
    line-height: normal;
}

.logo {
    text-align: left;
    position: absolute;
    left: 4.86%;
    right: 87.15%;
    top: 1.37%;
    bottom: 93.55%;
}

.commonbutton {
    position: absolute;
    width: 450px;
    height: 50px;
    background-color: #7852FB;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
   
}


@media(max-width:599px){
    .commonbutton {
        width: 100%
    }
}


@media (min-width: 599px) and (max-width: 1472px) {
    .commonbutton {
        width: 100%
    }
}
