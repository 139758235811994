@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
    font-family: 'Poppins',sans-serif;
}

.container {
    width: 100%;
    margin: 0px;
    height: 100vh;

}

.container-info {
    display: inline-block;
    position: relative;
    width: 100%;
    min-height: 100%; 
      flex-grow: 1;
    text-align:'center';
}

.logoStyle {
    left: 8%;
    top: 1.37%;
    position: relative;

}

.login_link {
    color: #7852FB;
    font-weight: 600;
    font-size: 14px;
    text-decoration: none;
    font-family: 'Poppins';
}

.container-main {
    display: inline-block;
    position: relative;
    width: 100%;
    min-height: 100%;
    flex-grow: 1;
  
}

.heading {
    text-align: left;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    color: #212121;
}

.terms{
    display: flex;
    align-items: flex-start;
    position: relative;
}

.terms_checkbox {
    box-sizing: border-box;
    display: flex;
    position: absolute;
    border-radius: 4px;
}
.term_link{
    color:#7852FB;
    font-size: 14px;
}

.login_link{
    color: #7852FB;
    font-weight: 600;
    font-size: 14px;
    text-decoration: none;
}

.required {
    color: red;
    position: absolute;
    left: 36px;
    top: 10px;
}

@media (min-width: 768px) {
    .container-info {
        float: left;
        left: 0;
        top: 0;
        height: 100%;
        width: 50%;
        flex-grow: 1;
        min-height: 100%;
    }
}

@media (min-width: 768px) {
    .container-main {
        float: right;
        /*position: fixed;*/
        right: 0;
        top: 0;
        height: 100%;
        width: 50%;
        flex-grow: 1;
        min-height: 100%;
    }
}


@media only screen and (max-width: 600px) {
    .container-main {
       display: none;
       
    }
}