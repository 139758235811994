.footer {
  background-color: #464646;
  width: 100%;
  min-height: 70px;
  padding: 0 1rem;
}

.footer-image {
    width: 100%;
    height: 40px;
}
