.nav {
  min-height: 70px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  background-color: #fff;
}

.nav-logo {
  width: 97px;
  height: 44px;
  margin-left: 18px;
  border-radius: 5px;
  cursor: pointer;
}

@media  (max-width:400px){
 .nav-logo{width: 44px;
  height: 22px;
  margin-left: 10px;}
}
@media (min-width:400px) and (max-width:600px){
  .nav-logo{width: 64px;
   height: 32px;
   margin-left: 10px;}
 }

.banner-logo{
  width: 100px;
  height: 50px;
  object-fit: contain;
}

@media (max-width:400px){
  .banner-logo{
    width: 70px;
    height: 35px;}
}


.nav-link {
  text-decoration: none;
  color: #000;
}

.nav-items {
  margin-block: 1rem;
}

.nav-items:hover {
  background: rgba(120, 82, 251, 0.09);
  border-right: 2px solid #7852fb;
}

.nav-items:hover a {
  color: #7852fb;
}

@media only screen and (max-width: 600px) {
  .nav > div {
    padding: 0 12px;
  }
  .nav-logo {
    padding-left: 0;
    margin-inline: 0;
    margin-top: 10px;
  }
}
