@media screen and (max-height: 1170px) {
  .author-name-xya {
    max-height: 30vh !important;
  }
}

@media screen and (max-height: 865px) {
  .author-name-xya {
    max-height: 20vh !important;
  }
}

@media screen and (max-height: 705px) {
  .author-name-xya {
    max-height: 10vh !important;
  }
}

.author-name-xya > ul {
  max-height: unset !important;
}
